import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {Navigate} from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import classes from "./Home.module.scss";
import userService from "../../services/user-services";
import {format} from 'date-fns';
import Loading from "../../components/Loading/Loading";
import close from '../../assets/Union 2.png'
import useFetch from "../../hooks/useFetch";


const Home = () => {
    const {user} = useAuth();
    const {logout} = useAuth();
    const [years, setYears] = useState([]);
    const [categories, setCategories] = useState([]);
    const [query, setFilters] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [selectedArray, setSelectedArray] = useState([]);
    const [deleteVideo, setDeleteVideo] = useState(null);
    const [deleteVideoModal, setDeleteVideoModal] = useState(false);
    const [shareModal, setShareModal] = useState({
        isVisible: false,
        step: 0,
        type: null
    });
    const {list, loading} = useFetch(query, page);
    const loader = useRef(null);
    const [shareType, setShareType] = useState(null)

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage(prev => (prev += 1));
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    useEffect(() => {
        userService.getCategories().then(result => {
            setCategories(result.data.categories);
            result.data.status === 403 && logout()
        }, error => {
            error.response.status === 403 && logout()
        });
        userService.getYears().then(result => {
            setYears(result.data.years);
            result.data.status === 403 && logout()
        }, error => {
            error.response.status === 403 && logout()
        });
    }, []);

    useEffect(() => {
        setPage(1)
    }, [query]);

    const deleteVid = id => {
        userService.deleteVideo(id).then(async res => {
                setFilters(prevState => prevState)
                setDeleteVideoModal(false);
                setIsLoading(false)
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                error.response.statusText === 'Unauthorized' && logout()
            }
        )
        ;
    }

    const getVideos = () => {
        return list.map(video => {
            const dt = format(new Date(video.published_at), 'yyyy. MM. dd.');
            return <div
                className={"grid grid-cols-[4fr_2fr_1fr_1fr_1fr_1fr] bg-[#ffffff50] mb-2 h-[90px] hover:bg-buttonHover/20 transition-all"}>
                <div className={"grid grid-cols-[160px_420px] max-h-[90px] gap-x-2 items-center"}>
                    <div className=""><img src={video.thumbnail_url}
                                           alt='video thumbnail képe'
                                           className={"object-contain h-[90px] mx-auto"}/></div>

                    <div className="flex flex-col my-auto">
                        <h3 className={"text-lg lg:text-sm text-black font-bold " + classes["line-clamp"]}>{video.title}</h3>
                        <p className={"text-xs font-montserratM " + classes["line-clamp"]}>{video.description}</p>
                    </div>
                </div>

                <div
                    className='flex flex-col text-xs gap-y-2 justify-center items-center'>{video.categories.map(cat => {
                    return <div
                        className={"font-montserratM text-center"}>{cat}</div>
                })}</div>
                <p className={"text-[10px] flex items-center justify-center"}>{dt}</p>
                <div className={"text-xs flex items-center gap-x-1 justify-center"}>{video.is_public ?
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 576 512" className="w-4 h-4 fill-brandGreen">
                        <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM432 256c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM288 192c0 35.3-28.7 64-64 64c-11.5 0-22.3-3-31.6-8.4c-.2 2.8-.4 5.5-.4 8.4c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6z"/>
                        <title>Publikus</title>
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="w-4 h-4 fill-baseText">
                        <path
                            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c5.2-11.8 8-24.8 8-38.5c0-53-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zm223.1 298L373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5z"/>
                        <title>Nem publikus</title>
                    </svg>} {video.is_visible ? <svg xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 512 512" className="w-4 h-4 fill-brandBlue">
                    <path
                        d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                    <title>Látható</title>
                </svg> : <svg xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512" className='w-4 h-4 fill-brandPrimary'>
                    <path
                        d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
                    <title>Nem látható</title>
                </svg>}</div>
                <div className={"text-xs flex items-center justify-center"}>{video.is_visible ?
                    <input type="checkbox"
                           defaultChecked={selectedArray.indexOf(video.id) > -1}
                           className={'accent-baseText'}
                           onChange={e => {
                               const selectArray = selectedArray;
                               if (e.target.checked) {
                                   selectArray.push(video.id);
                               } else {
                                   const index = selectArray.indexOf(video.id);
                                   if (index > -1) {
                                       selectArray.splice(index, 1);
                                   }
                               }
                               setSelectedArray(selectArray);
                           }
                           }/> : <></>}</div>
                <p className={"text-xs flex gap-x-2 items-center justify-center"}>
                    <a href={"/videok/szerkesztes/" + video.id}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512" className={'w-4 h-4 cursor-pointer hover:scale-110 transition-all'}>
                            <path
                                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                            <title>Szerkesztés</title>
                        </svg>
                    </a>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 448 512"
                         className='h-5 w-5 fill-red-700 cursor-pointer hover:scale-110 transition-all' onClick={() => {
                        setDeleteVideo(video);
                        setDeleteVideoModal(true);
                    }
                    }>
                        <path
                            d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                        <title>Törlés</title>
                    </svg>
                </p>
            </div>
        })
    }

    const getCategories = () => {
        return categories.map(category => {
            return <div className='mb-2'>
                <input type="checkbox" id={category.id}
                       className={'accent-brandPrimary hidden peer'} onChange={(e) => {
                    const categoryArray = query?.categories || [];
                    if (e.target.checked) {
                        categoryArray.push(category.id);
                    } else {
                        const index = categoryArray.indexOf(category.id);
                        if (index > -1) {
                            categoryArray.splice(index, 1);
                        }
                    }
                    setFilters(prevState => ({
                        ...prevState, 'categories': categoryArray
                    }));
                }
                }/>
                <label htmlFor={category.id}
                       className={"text-xs border cursor-pointer bg-lightgray border-brandPrimary py-1 px-3 rounded-full font-montserratM peer-checked:bg-brandPrimary peer-checked:text-white hover:bg-brandPrimary hover:text-white transition-all"}> {category.name}</label>
            </div>
        })
    }

    const getYears = () => {
        return years.map(year => {
            return <option value={year}>{year}</option>
        })
    }

    const yearSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState, year: value
        }));
    }

    const orderSelect = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState, order: value
        }));
    }

    const handleSearch = e => {
        const value = e.target.value;
        setFilters(prevState => ({
            ...prevState,
            search: value
        }))
    }

    const handleTypeChange = e => {
        const value = e.target.value;
        setShareType(value);
        setShareModal(prevState => ({
            ...prevState,
            type: value
        }))
    }

    const shareVideos = () => {
        userService.shareVideos(shareType, selectedArray).then(res => {
            setShareModal(prevState => ({
                ...prevState,
                step: 2
            }));
            setSelectedArray([]);
        }, (error) => {
            error.response.status === '403' && logout()
        })
    }

    if (!user) {
        return <Navigate to="/login"/>;
    }

    return (<><Layout padded={true}>
            <div className={"flex w-full " + classes["background"]}>
                <div className={"container mx-auto px-10 mt-11"}>
                    <div className={"flex flex-col md:grid md:grid-cols-[1fr_5fr]"}>
                        <div className={"mr-10 min-w-[161px] hidden md:flex md:flex-col"}>
                            <div className="w-full mb-2">
                                <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-2'}>Keresés</h3>
                                <input type="text" name='query' onChange={handleSearch}
                                       className='border border-gray rounded-md outline-none focus:border-brandPrimary px-2.5 py-2 w-full text-xs'
                                       placeholder='Keresés az videók között'/>
                            </div>
                            <div className={'border-b-2 border-brandSecondary my-2'}/>
                            <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-2'}>Rendezés</h3>
                            <select name="order" id="order" onChange={orderSelect}
                                    className={'w-full text-xs font-montserratM text-black bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                <option value="descDate">Legújabb videó</option>
                                <option value="ascDate">Legrégebbi videó</option>
                                <option value="a-z">A-Z</option>
                                <option value="z-a">Z-A</option>
                            </select>
                            <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-1 pt-5 border-t-2 border-brandSecondary'}>Kategóriák</h3>
                            <div className={'flex flex-col mb-5'}>
                                {getCategories()}
                            </div>
                            <h3 className={'uppercase text-brandPrimary font-bold text-sm mb-2 pt-5 border-t-2 border-brandSecondary'}>Év</h3>
                            <div className={'border-b-2 border-brandSecondary'}>
                                <select name="years" id="years" onChange={yearSelect}
                                        className={'w-full text-xs font-montserratM text-black bg-white border border-lightgray rounded-md px-2 h-7 mb-5'}>
                                    <option value="">Összes</option>
                                    {getYears()}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between mb-3">
                                <h1 className={"uppercase text-4xl leading-6 font-sans font-bold text-black"}
                                    id={"top"}>Videók</h1>
                                <div className="flex gap-x-2 mt-2">
                                    <button
                                        className="btn-secondary flex items-center fill-brandPrimary gap-x-2 text-brandPrimary hover:fill-white disabled:"
                                        onClick={() => setShareModal({
                                            isVisible: true,
                                            step: 0,
                                            type: null
                                        })}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 576 512"
                                             className={'w-4 h-4 '}>
                                            <path
                                                d="M384 24c0-9.6 5.7-18.2 14.5-22s19-2 26 4.6l144 136c4.8 4.5 7.5 10.8 7.5 17.4s-2.7 12.9-7.5 17.4l-144 136c-7 6.6-17.2 8.4-26 4.6s-14.5-12.5-14.5-22V224H352 337.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C242.8 374.8 160 333.4 160 240c0-79.5 64.5-144 144-144h80V24zM0 144C0 99.8 35.8 64 80 64H96c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H368c8.8 0 16-7.2 16-16V416c0-17.7 14.3-32 32-32s32 14.3 32 32v16c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V144z"/>
                                        </svg>
                                        Megosztás
                                    </button>
                                    <a href="/videok/uj-hozzaadasa"
                                       className='btn-primary flex items-center gap-x-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                             className='h-5 w-5 fill-white'>
                                            <path
                                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                                        </svg>
                                        új videó</a>
                                </div>
                                <a href={'#top'}
                                   className="fixed bottom-5 right-5 bg-brandPrimary px-3 pt-2 pb-5 flex items-center rounded-full text-[40px] leading-[20px] shadow-lg hover:bg-brandSecondary">&#9650;</a>
                            </div>
                            <div
                                className={"pb-4 border-b-2 border-brandSecondary mb-5"}>

                            </div>
                            <div
                                className={"grid grid-cols-[4fr_2fr_1fr_1fr_1fr_1fr] text-center py-3.5 text-[10px]"}>
                                <div className="text-md font-bold w-[580px] text-left">Videó</div>
                                <div className="text-md font-bold">Kategória</div>
                                <div className="text-md font-bold">Dátum</div>
                                <div className="text-md font-bold">Láthatóság</div>
                                <div className="text-md font-bold">Kiválasztás</div>
                                <div className="text-md font-bold">Műveletek</div>
                                <div
                                    className={"pb-4 border-b-2 col-span-6 border-brandSecondary my-2"}>
                                </div>

                            </div>

                            {isLoading ? <div className="col-span-6"><Loading/></div> : getVideos()}
                            {loading && <div className="col-span-6"><Loading/></div>}
                            <div ref={loader}/>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
            {
                deleteVideoModal &&
                <div
                    className="fixed top-0 left-0 bottom-0 right-0 bg-gray-900/50 flex justify-center items-center">
                    <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8 text-center">
                        <h1 className="font-header font-black text-2xl text-black uppercase">Videó törlése</h1>
                        <p className={'pb-5 border-b-2 border-brandSecondary mb-5 text-xs'}></p>
                        <p>Biztosan törli a következő videót:</p>
                        <p className="pb-5">
                        <span className="font-bold">
 {deleteVideo.title}
                        </span>
                            ?
                        </p>
                        <div className="flex px-10 w-full justify-between">
                            <button className='btn-secondary'
                                    onClick={() => setDeleteVideoModal(false)}>Mégsem
                            </button>
                            <button className='btn-primary' onClick={() => deleteVid(deleteVideo.id)}>Törlés
                            </button>
                        </div>
                    </div>
                </div>
            }

            {
                shareModal.isVisible &&
                <div
                    className="fixed top-0 left-0 bottom-0 right-0 bg-gray-900/50 flex justify-center items-center">
                    <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8 text-center relative">
                        {shareModal.step !== 2 &&
                            <div className="absolute top-8 right-8 cursor-pointer hover:hue-rotate-15"
                                 onClick={() => {
                                     setShareModal({isVisible: false, step: 0, type: 0})
                                 }
                                 }><img src={close}
                                        alt=""/>
                            </div>}
                        {shareModal.step === 0 &&
                            <>
                                <h1 className="font-header font-black text-2xl text-black uppercase">Videó
                                    megosztása</h1>
                                <p className={'pb-2 border-b-2 border-brandSecondary mb-5 text-xs text-black'}> Válaszd
                                    ki, hol
                                    szeretnéd
                                    megosztani a {selectedArray.length <= 1 ? 'videót' : 'videókat'}!</p>
                                <p className='pb-4'>Kiválasztott videó{selectedArray.length > 1 && 'k'}:</p>
                                {selectedArray.length !== 0 ? <>   <p className="pb-5 mx-10">

                                    {list.filter((selected) => selectedArray.indexOf(selected.id) > -1).map(video =>
                                        <div
                                            className="font-bold text-black text-left">{video.title}</div>)}
                                </p>
                                    <select
                                        className='w-fit mx-10 bg-white border-2 border-lightgray rounded px-2 py-1 mb-5'
                                        onChange={handleTypeChange}>
                                        <option selected={true} disabled={true} hidden={true}>Válaszd ki, hol
                                            szeretnéd
                                            megosztani a videót!
                                        </option>
                                        <option value="1">Megosztás Emailben</option>
                                        <option value="2">Megosztás Webexen</option>
                                        <option value="3">Megosztás Webexen és Emailben</option>
                                    </select>
                                    <div className="flex px-10 w-full justify-center">
                                        <button
                                            className='btn-primary flex gap-x-2 fill-white disabled:bg-gray-500 disabled:border-gray-500'
                                            onClick={() => setShareModal(prevState => ({
                                                    ...prevState,
                                                    step: 1
                                                })
                                            )} disabled={shareModal.type === null}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 576 512"
                                                 className={'w-4 h-4 '}>
                                                <path
                                                    d="M384 24c0-9.6 5.7-18.2 14.5-22s19-2 26 4.6l144 136c4.8 4.5 7.5 10.8 7.5 17.4s-2.7 12.9-7.5 17.4l-144 136c-7 6.6-17.2 8.4-26 4.6s-14.5-12.5-14.5-22V224H352 337.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C242.8 374.8 160 333.4 160 240c0-79.5 64.5-144 144-144h80V24zM0 144C0 99.8 35.8 64 80 64H96c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H368c8.8 0 16-7.2 16-16V416c0-17.7 14.3-32 32-32s32 14.3 32 32v16c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V144z"/>
                                            </svg>
                                            Megosztás
                                        </button>
                                    </div>
                                </> : <div className="font-bold text-black">
                                    Még nincsen kiválasztva videó megosztásra!
                                </div>}
                            </>}

                        {shareModal.step === 1 && <>
                            <h1 className="font-header font-black text-2xl text-black uppercase">Ellenőrzés
                            </h1>
                            <p className={'pb-2 border-b-2 border-brandSecondary mb-5 text-xs text-black'}> Biztosan
                                ki
                                akarod küldeni a {selectedArray.length === 1 ? 'videót' : 'videókat'}?
                            </p>
                            <p className='pb-4'>Kiválasztott videó{selectedArray.length > 1 && "k"}:</p>
                            <p className="pb-5 mx-10">
                                {list.filter((selected) => {
                                    return selectedArray.indexOf(selected.id) > -1;
                                }).map(video => <div
                                    className="font-bold text-black text-left">{video.title}</div>)}
                            </p>
                            <div className="flex px-10 w-full justify-between">
                                <div
                                    className="btn-secondary flex gap-x-2 items-center text-brandPrimary hover:text-white fill-brandPrimary hover:fill-white"
                                    onClick={() => {
                                        setShareModal(prevState => ({
                                            ...prevState,
                                            isVisible: false
                                        }))
                                    }
                                    }>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512" className='w-5 h-5 '>
                                        <path
                                            d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z"/>
                                    </svg>
                                    Mégsem
                                </div>
                                <button className='btn-primary flex gap-x-2 items-center fill-white'
                                        onClick={shareVideos}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 576 512"
                                         className={'w-5 h-5 '}>
                                        <path
                                            d="M384 24c0-9.6 5.7-18.2 14.5-22s19-2 26 4.6l144 136c4.8 4.5 7.5 10.8 7.5 17.4s-2.7 12.9-7.5 17.4l-144 136c-7 6.6-17.2 8.4-26 4.6s-14.5-12.5-14.5-22V224H352 337.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C242.8 374.8 160 333.4 160 240c0-79.5 64.5-144 144-144h80V24zM0 144C0 99.8 35.8 64 80 64H96c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H368c8.8 0 16-7.2 16-16V416c0-17.7 14.3-32 32-32s32 14.3 32 32v16c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V144z"/>
                                    </svg>
                                    Megosztás
                                </button>
                            </div>
                        </>}

                        {shareModal.step === 2 && <>
                            <h1 className="font-header font-black text-2xl text-black uppercase">Az üzenet elküldve
                            </h1>
                            <p className={'pb-2 border-b-2 border-brandSecondary mb-5 text-xs text-black'}> Bezárhatod
                                az ablakot.
                            </p>

                            <div className="flex px-10 w-full justify-center">
                                <button className='btn-primary flex gap-x-2 items-center fill-white'
                                        onClick={() => {
                                            setShareModal(prevState => ({
                                                ...prevState,
                                                isVisible: false
                                            }))
                                            setFilters(prevState => ({
                                                ...prevState
                                            }))
                                        }}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 320 512" className="w-4 h-4">
                                        <path
                                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                                    </svg>
                                    Bezárás
                                </button>
                            </div>
                        </>}
                    </div>
                </div>
            }
        </>

    )
};

export default Home;
